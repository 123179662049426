import React from 'react'; // , { useEffect } 
//import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import minia from './miniacct2yt.png';
import './style.scss';

//import './phone-style.scss';
//import './tablet-style.scss';
//import Welcome from './Welcome';
//import Dashboard from './Dashboard';
const Home = () => {
    //console.log(getInfo)
    //useEffect(getInfo, [getInfo]);
    //useEffect(toto, [toto]);
    //useEffect(select, [select]);
    //useEffect(getConfig, [getConfig]);
    //useEffect(getConfig, [getConfig]);
    //useEffect(getConfig, [getConfig]);
    //const token = useSelector(state => state.home.user.toto);
    //const isLogged = useSelector(state => state.home.user.logged);
    //console.log(isLogged)
    const showVideo = () => {
        document.querySelector('.video-thumbnail').style.display = "none";
        let x = document.querySelector('.video')
        console.log(x)
        x.style.display = "flex";

    }
    return (
        <div className="home">
            <a className='home-download' target="_blank" rel="noreferrer" href='https://download.crossout-chat-translator.top/cct2.exe'>
                <button className="noselect"></button>
            </a>
            <div className='home-par'>
                <h1 className='home-par-title'>Crossout Chat Translator 2</h1>
                <p className='home-par-text'>This app translate the Crossout in-game chat and display the translation in several languages. Made with electron and the iamtraction-google-translate library, this app is developed and maintained by Quantum and Earlam. This application allows you to understand what the players say in game when they insult you. It's a fact. Nevertheless, our first wish is to bring people closer with this application. Who has never dreamed of being able to read the instructions of a mate who writes Spanish when the rest of the team is English-writing? This magic app does not require an API key so you don't have to pay anything. Keep in mind that this app uses google. We (Earlam and Quantum) will not interfere with, read, store or resell your data. This application is free and open-source. You can also contribute to it by opening an issue on its official github repository. One last important thing: Beware of third-party software that promises you mountains and wonders... And be sure to only acquire our cheat here by clicking on the download button of this official website. Enjoy! Earlam.</p>
            </div>
            <div className='rs'>
                <a className='rs-lnk' target="_blank" rel="noreferrer" href='https://www.buymeacoffee.com/earlam'>
                <p className='rs-coffee-text'>Donate</p>
                    <div className='rs-coffee'></div>
                </a>
                <a className='rs-lnk' target="_blank" rel="noreferrer" href='https://discord.com/invite/cknUdH94Cn'>
                <p className='rs-discord-text'>Support</p>
                    <div className='rs-discord'></div>
                </a>
                <a className='rs-lnk' target="_blank" rel="noreferrer" href='https://github.com/JimmyGaubert/CCT2'>
                <p className='rs-github-text'>Contribute</p>
                    <div className='rs-github'></div>
                </a>
            </div>
            
            <iframe className='video' width="450" height="258" src="https://www.youtube-nocookie.com/embed/c74gQv3vJMY?autoplay=0&controls=2&modestbranding=1&autohide=0&rel=0&showinfo=0" title="YouTube video player" frameBorder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            <img height="180" className='video-thumbnail' src={minia} alt='' onClick={showVideo}></img>

        </div>
    );
};

Home.propTypes = {
    //getInfo: PropTypes.func.isRequired,
};

export default Home;
