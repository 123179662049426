import React from 'react';
//import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

//import { useSelector } from 'react-redux'
import './style.scss';

const Footer = () => {
  //const truc = useSelector(state => state.home.accueil)
  //console.log(truc)
  return (
    <footer className="footer">
        <div className='footer-items'>
        </div>
    </footer>
  )

};

Footer.propTypes = {

};

export default Footer;
