import { connect } from 'react-redux';
import Home from '../../components/App/Home';//src/components/App/Home/
//import { getInfo, toto, getConfig, select, updateConfig } from '../../actions';

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
  /*
  getInfo: () => {
    dispatch(getInfo());
  },
  toto: () => {
    dispatch(toto());
  },
  getConfig: () => {
    dispatch(getConfig());
  },
  select: () => {
    dispatch(select());
  },
  updateConfig: () => {
    dispatch(updateConfig())
  }
  */
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
 