import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

const Error404 = () => {
  //const wStyle = {
  //  textTransform: 'lowercase',
  //};
  return (
    <div className="error404">
      <img className="error404-img"src="https://www.aht.li/3646005/Untitled_2_16.png" alt="404"/>
      <p className="error404-text">Erreur 404 ...</p>
      <Link to="/" className="error404-link">        
        Retour à l'accueil
      </Link>
    </div>
  );
};

export default Error404;
