/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
import React from 'react';
// import PropTypes from 'prop-types';
/*import Menu from 'src/containers/Menu';
import Form from 'src/containers/Form';
import Logo from './Logo';*/
import './style.scss';
//import { Link } from 'react-router-dom';
//import { useSelector } from 'react-redux'
const Navbar = () => {
  
  return (
    <header className="navbar">
      <div className="navbar-links">
        
      </div>
    </header>
  );
};

Navbar.propTypes = {

};

export default Navbar;
