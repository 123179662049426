/* eslint-disable import/no-extraneous-dependencies */
import { Routes, Route } from 'react-router-dom'; // BrowserRouter, , Redirect, useLocation, Navigate
//import PropTypes from 'prop-types';
import React from 'react'; //, { useEffect }
import Home from '../../containers/Home';//'./Home'; //src/containers/Home

import Navbar from './Navbar';
import Footer from './Footer';
import Error404 from './404';
/*
import Cgu from './Cgu';
import Team from './Team';
import Login from './Login';
import Logout from './Logout';
import Blog from '../../containers/Blog';
//import Serv from './Home/Serv';
*/
///////////////////////////////////////////////
//import Commands from './Commands';
//import Blog from './Blog';
//import MentionLegales from './MentionLegales';

import './style.scss';
//fetchTags, 
//fetchAdventureGames,
//isConnected, 
//closeBurger,
const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Routes>
        {/* routes diverses
        <Route exact path="/mentions-legales" element={<Error404 />}/>
        <Route exact path="/contact" element={<Error404 />}/>
        <Route exact path="/statuts" element={<Statuts />}/>
        <Route exact path="/commands" element={<Commands />}/>
        <Route exact path="/cgu" element={<Cgu />}/>
        <Route exact path="/team" element={<Team />}/>
        <Route exact path="/blog" element={<Blog />}/>
        <Route exact path="/login" element={<Login />}/>
        <Route exact path="/logout" element={<Logout />}/>
        <Route exact path="/auth" element={<Navigate to="/"/>}/>
        <Route exact path="/serv" element={<Serv />}/>
        */}
        <Route exact path="/" element={<Home />}/>
        <Route element={<Error404 />}/>
      </Routes> 
      <Footer />
    </div>
  );
};
App.propTypes = {
};

export default App;