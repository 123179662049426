import { connect } from 'react-redux';
import App from '../../components/App';//src/components/App
//import { getInfo, toto, getConfig, select, updateConfig } from '../../actions'; //src/actions
// isConnected: state.users.isConnected,
//  tags: state.site.tags,
const mapStateToProps = (state) => ({
  //accueil: state.home.accueil,
  //user: state.home.user,
  //guilds: state.home.guilds,
  //selected: state.home.selected,
});
  
// fetchAdventureGames: (id) => {
//   dispatch(fetchAdventureGames(id));
// },
// closeBurger: () => {
//   dispatch(closeBurger());
// },
const mapDispatchToProps = (dispatch) => ({
  /*
  getInfo: () => {
    dispatch(getInfo());
  },
  toto: () => {
    dispatch(toto());
  },
  getConfig: () => {
    dispatch(getConfig());
  },
  select: () => {
    dispatch(select());
  },
  updateConfig: () => {
    dispatch(updateConfig())
  }
  */
});
 
export default connect(mapStateToProps, mapDispatchToProps)(App); 
